
import { createSlice } from '@reduxjs/toolkit';
import { authWithBackend } from './config.slice';
import { refreshCurrentDeposit } from './playtoken.slice';
import { Buffer } from 'buffer';
import { BACKEND_API } from '../../settings';
import { fetchQueryParams } from '../query/query_params';

const initialState = {
    latestWinners: [],
    playersOnline: null,
    latestRollResult: null,
    spinning: false,
    multiplier: 1,
    wsConnectionActive: false,
    myWins: [],
};

function filterByTxHash() {
    const results = {};

    return function(event) {
        if (!results[event.TxHash]) {
            results[event.TxHash] = true
            return true
        }
        return false
    }
}

const eventsSlice = createSlice({
    name: 'events',
    initialState,
    reducers: {
        setLatestRollResult: (state, { payload }) => {
            state.latestRollResult = payload;
        },
        appendWinners: (state, { payload }) => {
            state.latestWinners = [...payload, ...state.latestWinners].filter(filterByTxHash()).slice(0, 20);
        },
        appendMyWins: (state, { payload }) => {
            state.myWins = [...payload, ...state.myWins].filter(filterByTxHash()).slice(0, 20);
        },
        setPlayersOnline: (state, { payload }) => {
            state.playersOnline = payload;
        },
        setSpinning: (state, { payload }) => {
            state.spinning = payload;
        },
        setMultiplier: (state, { payload }) => {
            state.multiplier = payload;
        },
        setWSConnectionActive: (state, { payload }) => {
            state.wsConnectionActive = payload;
        }
    },
});

export const { setLatestRollResult, appendWinners, appendMyWins, setPlayersOnline, setSpinning, setMultiplier, setWSConnectionActive } = eventsSlice.actions;

export function setWebsocketConnectionIsActive(isActive) {
    return function (dispatch, getState) {
        dispatch(setWSConnectionActive(isActive));
    }
}

export function handleEvent(event, recaptchaRef) {

    return function (dispatch, getState) {
        const currentAddress = getState().etherium.currentAccount;

        switch (event.name) {
            case "ROLL_RESULTS":
                for (let i = 0; i < event.data.length; i++) {
                    const result = event.data[i];

                    if (result.Player.toLowerCase() == currentAddress.toLowerCase()) {
                        dispatch(setLatestRollResult(result));
                        dispatch(setSpinning(false));
                        break;
                    }
                }
                break;
            case "WINNERS":
                const myWins = event.data.filter(event => event?.Player.toLowerCase() == currentAddress?.toLowerCase());
                dispatch(appendWinners(event.data));
                if (myWins.length) {
                    dispatch(appendMyWins(myWins));
                }
                break;
            case "PLAYERS_ONLINE":
                dispatch(setPlayersOnline(event.data));
                break;
            case "RESPONSE":
                // Need to re-auth
                if (event.data.event === 'AUTH_HEARTBEAT' && !event.data.success) {
                    dispatch(authWithBackend(recaptchaRef));
                }
                break;
        }
    }
}

export function roll(recaptchaRef, slotMachineContract, erc20PaytokenContract) {

    return async function (dispatch, getState) {
        dispatch(setSpinning(true));
        try {
            let accessToken = getState().configuration.accessToken;
            const currentAccount = getState().etherium.currentAccount;
            dispatch(setLatestRollResult(null));
    
            let queryParams = fetchQueryParams();
    
            if (queryParams) {
                queryParams = '?q=' + queryParams
            }
    
            let response = await fetch(BACKEND_API + 'api/v1/roll' + queryParams, {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': accessToken,
                },
                // Pass multiplier from UI values from 1 to 20
                body: JSON.stringify({
                    // Take multiplier from UI
                    multiplier: getState().events.multiplier,
                    player: currentAccount,
                })
            });
            if (response.status === 401) {
                dispatch(authWithBackend(recaptchaRef))
                // Get new accessToken after authWithBackend
                accessToken = getState().configuration.accessToken;
    
                response = await fetch(BACKEND_API + 'api/v1/roll' + queryParams, {
                    method: 'POST',
                    headers: {
                        'Content-type': 'application/json',
                        'Authorization': accessToken,
                    },
                    // Pass multiplier from UI values from 1 to 20
                    body: JSON.stringify({
                        // Take multiplier from UI
                        multiplier: getState().events.multiplier,
                        player: currentAccount,
                    })
                });
            }
    
            const body = await response.json();
            try {
    
                const tx = await slotMachineContract.roll(body.id, Buffer.from(body.random_bytes, 'hex'), body.multiplier, Buffer.from(body.backend_signature, 'hex'));
                await tx.wait();
    
                dispatch(refreshCurrentDeposit(erc20PaytokenContract));
            } catch (e) {
                // TODO show message? Or retry
                console.log('slotmachineContract.roll e: ', e);
            }
        } catch (e) {
            console.log(e);
        } finally {
            dispatch(setSpinning(false));
        }
    }
}

export default eventsSlice.reducer;