
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    deposit: null,
};

const playTokenSlice = createSlice({
    name: 'playtoken',
    initialState,
    reducers: {
        setDeposit: (state, { payload }) => {
            state.deposit = payload;
        },
    },
});

const { setDeposit } = playTokenSlice.actions;

export function refreshCurrentDeposit(erc20PaytokenContract) {

    return async function(dispatch, getState) {
        const configuration = getState().configuration.config;
        const playerAddress = getState().etherium.currentAccount;

        const result = await erc20PaytokenContract.allowance(playerAddress, configuration.slotmachine_address);
        const allowedDeposit = result.div(10 ** configuration.paytoken_decimals);

        dispatch(setDeposit(allowedDeposit.toString()));
    }
}

export function playerSetDeposit(erc20PaytokenContract, deposit) {

    return async function(dispatch, getState) {
        const configuration = getState().configuration.config;

        const allowance = deposit * (10 ** configuration.paytoken_decimals);
        const tx = await erc20PaytokenContract.approve(configuration.slotmachine_address, allowance);
        await tx.wait();
        
        
        dispatch(refreshCurrentDeposit(erc20PaytokenContract))
    }
}



export default playTokenSlice.reducer;