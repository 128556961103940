
import { createSlice } from '@reduxjs/toolkit';
import Web3 from 'web3';
import { HARDHAT_LOCAL_URL } from '../../settings';


const initialState = {
    isMetamaskInstalled: undefined,
    networkVersion: null,
    currentAccount: null,
};

const etheriumSlice = createSlice({
    name: 'etherium',
    initialState,
    reducers: {
        setIsMetamaskInstalled: (state, { payload }) => {
            state.isMetamaskInstalled = payload;
        },
        setCurrentAccount: (state, { payload }) => {
            state.currentAccount = payload.account;
        },
        setNetworkVersion: (state, { payload }) => {
            state.networkVersion = payload;
        },
    },
});

const {
    setIsMetamaskInstalled,
    setCurrentAccount,
    setNetworkVersion,
} = etheriumSlice.actions;

export function checkIsMetamaskInstalled(dispatch, getState) {
    if (window?.ethereum?.isMetaMask) {
        dispatch(setIsMetamaskInstalled(true));
        return
    }
    dispatch(setIsMetamaskInstalled(false));
}

export function updateCurrentAccount(address) {
    return function (dispatch, getState) {
        dispatch(setCurrentAccount(address));
    }
}

export function updateAddress(address) {
    return function (dispatch, getState) {
        dispatch(setCurrentAccount({ account: address }));
    }
}

export function updateNetworkVersion(newNetwork) {
    return function (dispatch, getState) {
        dispatch(setNetworkVersion(newNetwork));
    }
}

const hardhatChainID = 31337;

export async function switchNetwork(dispatch, getState) {
    const backendConfig = getState().configuration.config;

    try {
        try {
            await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: Web3.utils.toHex(backendConfig.chain_id) }]
            });
            dispatch(updateNetworkVersion(window.ethereum.networkVersion));
        } catch (err) {
            // This error code indicates that the chain has not been added to MetaMask
            // if chainID
            if (err.code === 4902 && backendConfig.chain_id == hardhatChainID) {
                await window.ethereum.request({
                    method: 'wallet_addEthereumChain',
                    params: [
                        {
                            chainName: 'Hardhat Local network',
                            chainId: Web3.utils.toHex(backendConfig.chain_id),
                            nativeCurrency: { name: 'Etherium', decimals: 18, symbol: 'ETH' },
                            rpcUrls: [HARDHAT_LOCAL_URL]
                        }
                    ]
                });
            }
        }
    } catch (error) {
        console.log(error);
    }
}

export default etheriumSlice.reducer;