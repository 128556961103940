import React from 'react';
import check from "../../../assets/svg/check.svg";
import classes from './Network.module.scss';
// import { switchNetwork } from '../../../store/slices/etherium.slice';
// import { useDispatch } from 'react-redux';


export const Network = ({backendConfig, etheriumConfiguration}) => {
    // const dispatch = useDispatch();

    if (!backendConfig) {
        return null;
    }

    // if (!etheriumConfiguration.networkVersion || (etheriumConfiguration.networkVersion.toString() !== backendConfig.chain_id.toString())) {
    //     return (<button onClick={() => {dispatch(switchNetwork)}}>Switch network</button>)
    // }

    return null;
};

export const NetworkOk = ({backendConfig, etheriumConfiguration}) => {
    if (!backendConfig) {
        return null;
    }
    if (!etheriumConfiguration.networkVersion || (etheriumConfiguration.networkVersion.toString() !== backendConfig.chain_id.toString())) {
        return null;
    }

    return (<div className={classes.Network}><img src={check} alt="" width={20}/>Network is fine</div>)
};

export default Network;