import { useSelector } from 'react-redux';
import classes from './Winners.module.scss';
import { SCAN_SITE_URL } from '../../settings';
import {useState} from "react";

const WinnersTable = () => {
    const [activeTab, setActiveTab] = useState(1);
    const latestWinners = useSelector((state) => state.events.latestWinners);
    const myWins = useSelector((state) => state.events.myWins);
    const decimal = useSelector((state) => state.configuration.config.paytoken_decimals);

    function renderWinnders() {
        return [...latestWinners].map((winEvent, i) => {
            return (
                // TODO Add also icons of combination here!
                <div key={i}> {i + 1} {winEvent.Player} - {winEvent.WinAmount} USDT</div>
            )
        })
    }

    const sliceString = (str, maxLength) => {
        const start = str.slice(0, Math.floor(maxLength / 2));
        const end = str.slice(-Math.floor(maxLength / 2));
        return start + '...' + end;
    };

    const scanSiteLinkForTx = (txHash) => {
        return <a className='winners-tx-link' target="_blank" href={SCAN_SITE_URL + 'tx/' + txHash}>{sliceString(txHash, 10)}</a>
    }

    const arrayToRender = activeTab === 1 ? [...latestWinners] : myWins;

    return (
        <div className={classes.Winners}>
            <div className={classes.container}>
                <div className={classes.table_container}>
                    <div className={classes.tabsBlock}>
                        <h4>Latest winners</h4>
                        <div className={classes.tabs}>
                            <button className={activeTab === 1 ? classes.active : ''} onClick={() => setActiveTab(1)}>Live</button>
                            <button className={activeTab === 2 ? classes.active : ''} onClick={() => setActiveTab(2)}>My</button>
                        </div>
                    </div>
                    <table className={classes.table}>
                        <thead>
                            <tr>
                                <th>Tx Hash</th>
                                <th>Wallet</th>
                                <th>Combination</th>
                                <th>Multiplier</th>
                                <th>Win</th>
                            </tr>
                        </thead>
                        <tbody>
                            {arrayToRender.map((winEvent, i) => (
                                <tr key={i}>
                                    <td>{scanSiteLinkForTx(winEvent.TxHash)}</td>
                                    <td>{sliceString(winEvent.Player, 10)}</td>
                                    <td>
                                        <img width={'20'} height={'20'} src={`./images/reels/png-format/${winEvent.Slot1}.png`} alt="" />
                                        <img width={'20'} height={'20'} src={`./images/reels/png-format/${winEvent.Slot2}.png`} alt="" />
                                        <img width={'20'} height={'20'} src={`./images/reels/png-format/${winEvent.Slot3}.png`} alt="" />
                                    </td>
                                    <td>x{winEvent.Multiplier}</td>
                                    <td>{winEvent.WinAmount} USDT</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    );
};

export default WinnersTable;
